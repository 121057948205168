<template>
  <div class="Teacher-Questions">
    <div class="stu-module-header">
      <div class="stu-module-title">在线答疑</div>
    </div>
    <div class="content" v-if="queryRoot">
      <el-form :model="form" size="medium" inline>
        <el-form-item label="课程名称">
          <el-select v-model="form.courseId">
            <el-option
              v-for="(item, index) in courseIdOptions"
              :key="index"
              :label="item.courseName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="query" type="primary">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="tableData" :height="height" stripe v-loading="loading">
        <el-table-column label="序号" :width="60" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (current - 1) * pageSize + 1 }} </span>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(item, index) in columns"
          :prop="item.prop"
          :key="index"
          :label="item.label"
          :min-width="item.width"
          align="center"
        ></el-table-column>
        <el-table-column
          label="操作"
          :width="80"
          align="center"
          v-if="updateRoot"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="onDetails(scope.row)">
              查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        background
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <no-root v-else></no-root>
    <Details
      :id="id"
      :courseId="courseId"
      @multi="multi"
      ref="t_question_detail"
    />
  </div>
</template>

<script>
import {
  QueryAllEduAnswering,
  QueryAllCourseWorkSetByCourseIdByTeaId,
} from "@/libs/api/teacher/online";
import Details from "./module/Details.vue";
export default {
  name: "teacher-questions",
  components: { Details },
  data() {
    return {
      height: window.innerHeight - 400,
      form: {
        courseId: null,
      },
      courseIdOptions: [],
      loading: false,
      columns: [
        { prop: "title", label: "标题", width: 180 },
        { prop: "name", label: "发布人" },
        { prop: "studentNo", label: "学号" },
      ],
      tableData: [],
      total: 0,
      pageSize: 15,
      current: 1,
      id: null,
      courseId: null,
    };
  },
  computed: {
    queryRoot() {
      return this.$sysPerKey("eduTeacherQuestion:list");
    },
    updateRoot() {
      return this.$sysPerKey("eduTeacherQuestion:update");
    },
  },
  watch: {
    queryRoot(n) {
      n && this.selectInit();
    },
  },
  mounted() {
    this.queryRoot && this.selectInit();
  },
  methods: {
    selectInit() {
      QueryAllCourseWorkSetByCourseIdByTeaId().then((res) => {
        if (res.data && res.data.length) {
          this.courseIdOptions = res.data;
          this.form.courseId = res.data[0].id;
          this.query();
        }
      });
    },
    query() {
      if (!this.form.courseId) {
        return this.$message.error("请选择课程名称");
      }
      let data = new FormData();
      data.append("page", this.current);
      data.append("pageSize", this.pageSize);
      data.append("type", "2");
      data.append("courseId", this.form.courseId);
      this.loading = true;
      QueryAllEduAnswering(data)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.list;
          this.total = res.data.total;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onDetails(row) {
      if (row) {
        this.id = row.id;
        this.courseId = row.courseId;
        this.$refs["t_question_detail"].drawer = true;
      }
    },
    handleCurrentChange(page) {
      if (page) {
        this.current = page;
        this.query();
      }
    },
    multi() {
      this.query();
    },
  },
};
</script>

<style scope lang="less">
.Teacher-Questions {
  background: #fff;
  box-shadow: @shadow;
  .stu-module-header {
    padding: 20px;
    border-bottom: 1px solid @borderBottomColor;
  }
  .content {
    padding: 20px;
  }
}
</style>